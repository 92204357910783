import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import ArrowRight from 'src/components/icons/ArrowRightIcon';

import PageLink from 'src/components/navigation/PageLink';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  newsLink: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

export default function NewsGridHeader(props) {
  const { title, linkText, linkPage, ...passProps } = props;
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      alignItems="center"
      container
      {...passProps}
    >
      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1">{title}</Typography>
      </Grid>
      <Grid
        className={classes.newsLink}
        item
        xs={12}
        sm={6}
        container
        justify="flex-end"
      >
        <PageLink page={linkPage}>
          <Button
            className={classes.button}
            startIcon={<ArrowRight color="primary" />}
            variant="text"
          >
            {linkText}
          </Button>
        </PageLink>
      </Grid>
    </Grid>
  );
}
