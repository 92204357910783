import PT from 'prop-types';

export { default as NewsList } from './NewsList';
export { default as NewsGrid } from './NewsGrid';
export { default as NewsGridHeader } from './NewsGridHeader';
export { default as NewsTeaser } from './NewsTeaser';

export const NewsProps = {
  /** id of the news element */
  id: PT.string,
  /** optional: link to detail page */
  url: PT.string,
  /** optional: slug of news */
  slug: PT.string,
  /** the title of the news */
  title: PT.string.isRequired,
  /** optional: outlink */
  externalUrl: PT.string,
  /** the date of the news */
  date: PT.oneOfType([PT.number, PT.string, PT.object]),
  /** optional: link to an image for this news */
  imageUrl: PT.string,
  /** optional teaser text for the news */
  teaser: PT.string,
  /** how to display the link of the news */
  linkAs: PT.oneOf(['button', 'title', 'full']),
};
