import React from 'react';
import { graphql } from 'gatsby';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { transformNews } from 'src/data/transformers';

import PageHeader from 'src/components/content/PageHeader';
import NewsList from 'src/components/news/NewsList';
import PageTitle from 'src/components/content/PageTitle';

const NewsPage = ({ data, pageContext, location }) => {
  const {
    craft: { entries: allNews },
  } = data;

  return (
    <>
      <PageHeader />
      <Container maxWidth="xl">
        <Box pt={16} pb={8} textAlign="center">
          <PageTitle>{pageContext.title}</PageTitle>
        </Box>
        <NewsList news={allNews.map(transformNews)} />
      </Container>
    </>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsQuery($locale: String) {
    craft {
      entries(
        site: [$locale]
        type: "news"
        limit: 4
        orderBy: "dateCreated DESC"
      ) {
        dateCreated
        title
        ...newsData
      }
    }
  }
`;
