import React from 'react';
import PT from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Image from 'gatsby-image';

import { NewsProps } from 'src/components/news';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExternalLinkIcon from '@material-ui/icons/CallMadeOutlined';

import Typography from 'src/components/Typography';

const useStyles = makeStyles(theme => ({
  news: {
    width: '100%',
    padding: theme.spacing(2),
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  date: {
    fontSize: 13,
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
  },
  outlink: {
    textAlign: 'right',
    float: 'right',
  },
  newsImage: {
    marginTop: 4,
    width: '100%',
  },
}));

export default function NewsList(props) {
  const { news, formatDate } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={2}>
      {news.map(news => {
        return (
          <Grid
            id={news.id}
            key={news.id}
            item
            container
            xs={12}
            spacing={2}
            className={classes.news}
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4} className={classes.image}>
              <Image
                className={classes.newsImage}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={news.image.title}
                fluid={news.image.imageAssetFile.childImageSharp.fluid}
              />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.content}>
              <Typography variant="body1" className={classes.date}>
                {formatDate(news.date)}
              </Typography>
              <Typography variant="body2" className={classes.title}>
                {news.title}
              </Typography>
              <Typography component="div" variant="body1">
                <div dangerouslySetInnerHTML={{ __html: news.content }} />
              </Typography>

              {news.externalUrl && (
                <Button
                  className={classes.outlink}
                  startIcon={<ExternalLinkIcon color="primary" />}
                  variant="text"
                  size="small"
                  target="_blank"
                  href={news.externalUrl}
                >
                  {t('labels.linkToOriginal')}
                </Button>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

NewsList.propTypes = {
  news: PT.arrayOf(PT.shape(NewsProps)),
  formatDate: PT.func,
};
