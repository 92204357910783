import React from 'react';
import { Link } from 'gatsby';
import { useLocalization } from 'src/providers/LocalizationProvider';

const LocalizedLink = ({ to, ...props }) => {
  const { locale, localizeLink } = useLocalization();
  return <Link {...props} to={localizeLink(to, locale)} />;
};

export default LocalizedLink;
