import React from 'react';
import Grid from '@material-ui/core/Grid';
import PT from 'prop-types';
import LocalizedLink from 'src/components/navigation/LocalizedLink';

import { NewsProps, NewsTeaser } from 'src/components/news';

export const NewsGridTypes = {
  news: PT.arrayOf(PT.shape(NewsProps)),
  transformFn: PT.func,
  Component: PT.func,
};

export default function NewsGrid(props) {
  const { news = [], Component = NewsTeaser, transformFn = n => n } = props;

  return (
    <Grid container spacing={2}>
      {news.map(n => {
        const theNews = transformFn(n);
        return (
          <Grid item xs={12} sm={6} md={3} key={theNews.id}>
            <LocalizedLink to={theNews.url}>
              <Component {...theNews} />
            </LocalizedLink>
          </Grid>
        );
      })}
    </Grid>
  );
}

NewsGrid.propTypes = NewsGridTypes;
