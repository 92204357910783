import React from 'react';

import Image from 'gatsby-image';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NewsProps } from 'src/components/news';

const useStyles = makeStyles(theme => ({
  container: {},
  image: {
    maxWidth: '100%',
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const NewsTeaser = props => {
  const {
    id,
    title,
    // imageUrl = null,
    image,
    linkAs,
    externalUrl,
    teaser,
    renderImage = null,
    ...passProps
  } = props;

  const classes = useStyles(props);

  return (
    <Box {...passProps} className={classes.container} data-newsid={id}>
      {image ? (
        <Box data-testid="news-image">
          {typeof renderImage === 'function' ? (
            renderImage(props)
          ) : (
            <Image
              className={classes.image}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={image.title}
              fluid={image.imageAssetFile.childImageSharp.fluid}
            />
          )}
        </Box>
      ) : null}
      <Typography
        className={classes.title}
        align="left"
        variant="h6"
        color="textPrimary"
      >
        {title}
      </Typography>
    </Box>
  );
};

NewsTeaser.propTypes = NewsProps;

export default NewsTeaser;
